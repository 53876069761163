<template>
  <section class="service-details">
    <!-- <template v-if="GlobalServices.length"> -->
    <main-jumbotron
      :is-gradient="false"
      :main-headers="mainHeaders"
      :illustSrc="
        currentParentService.main_image
          ? currentParentService.main_image.path
          : ''
      "
    />

    <card-group
      :group-title="currentParentService.name"
      :services="subServices"
      :is-specific="true"
      :all-services="true"
    />
    <b-container>
      <article>
        <h2 class="main-heading">{{ $t("heading.description") }}</h2>
        <p
          class="main-description"
          v-html="currentParentService.description"
        ></p>
      </article>
      <advantages-list :list-data="listData" class="mt-5" :isService="true" />
    </b-container>
    <!-- </template> -->

    <template
      v-if="currentParentService.plans && currentParentService.plans.length > 0"
    >
      <Plans />
    </template>

    <subscribe class="my-5" />
  </section>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
import { mapGetters } from "vuex";

export default {
  name: "ServiceDetails",
  metaInfo() {
    return {
      title: this.mainHeaders.main,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.mainHeaders.main,
        },
        {
          vmid: "description",
          name: "description",
          content: this.mainHeaders.secondary,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${this.mainHeaders.main} خدمة سولوشن بلص`,
        },
      ],
    };
  },
  components: {
    CardGroup: () => import("@/components/Services/CardGroup"),
    AdvantagesList: () => import("@/components/AdvantagesList"),
    Subscribe: () => import("@/components/Subscribe"),
    Plans: () => import("@/components/Plans"),
  },
  mounted() {
    this.getSubServices();
  },
  computed: {
    listddd() {
      return this.mainHeaders.main;
    },
    ...mapGetters(["GlobalServices"]),
    currentParentService() {
      if (this.GlobalServices.length) {
        let parentService = this.GlobalServices.filter(
          (service) => service.id == this.$route.params.id
        );

        return parentService[0];
      } else {
        return {
          name: "",
          short_description: "",
          description: "",
          features: [{ name: "" }],
        };
      }
    },
    mainHeaders() {
      if (this.currentParentService) {
        return {
          main: this.currentParentService.name,
          secondary: this.currentParentService.short_description,
        };
      } else {
        return {};
      }
    },
    listData() {
      if (this.currentParentService) {
        let data = { title: "technologies" };
        data.items = this.currentParentService.features;

        return data;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      //   mainHeaders: {
      //     main: "Graphic Design",
      //     secondary:
      //       "Building highly-polished SaaS brand experiences that transform business and accelerate the speed of innovation."
      //   },
      subServices: [],
      //   listData: {
      //     title: "features",
      //     items: [
      //       "There are many eCommerce website companies to choose from, and different companies will tell you ALL kinds of different things that can make it hard to compare apples to apples. So it's important to understand the factors that go into website development pricing.",
      //       "In some ways, getting an eCommerce sale is like a race; if all other factors are equal, whoever has the better website and is found first wins. Some websites perform like Kias and some like Ferraris. And just like cars and most other things, some eCommerce websites are easier to build than others.",
      //       'There are two main questions I ask potential clients before estimating an ecommerce website cost: "What are your sales and traffic goals?" and "What custom features will you need for the website to meet them?" Your answers can can vary greatly depending on whether you\'re a start-up, market leader or something in between. So it makes sense that eCommerce website development costs can vary greatly, too.'
      //     ]
      //   }
    };
  },
  methods: {
    getSubServices() {
      IndexData({
        reqName: `sub-services?service_id=${this.$route.params.id}`,
      })
        .then((res) => {
          const { sub_services } = res.data;

          sub_services.forEach((subService) => {
            this.subServices.push({
              id: subService.id,
              title: subService.name,
              description: subService.short_description,
              image: subService.icon,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

